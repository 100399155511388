import { c } from "../utils/c";

export type PanelProps = {
  className?: string;
  children: React.ReactNode;
};

export type PanelTitleProps = {
  title: string;
  actions?: React.ReactNode[];
};

export function Panel(props: PanelProps) {
  return <div className={c("border rounded-xl shadow-md", props.className)}>{props.children}</div>;
}

export function PanelTitle(props: PanelTitleProps) {
  return (
    <div className="py-2 px-4 border-b flex justify-between items-center text-gray-700 grow-0">
      <div className="font-semibold text-sm">{props.title}</div>
      {props.actions !== undefined && props.actions.length > 0 && <div className="flex gap-4">{props.actions}</div>}
    </div>
  );
}
