import { forwardRef } from "react";
import { c } from "../utils/c";

export type Participant = {
  id: number;
  fullName: string;
  color: string;
};

export type Fragment = {
  fragmentId: number;
  participantId: number;
  startTimeSeconds: number;
  endTimeSeconds: number;
};

export type TranscriptItemProps = {
  active: boolean;
  participant: Participant;
  startTimeSeconds: number;
  onTimeClick: (seconds: number) => void;
  transcript: string;
};

function formatSeconds(s: number) {
  const date = new Date(s * 1000);

  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const seconds = date.getUTCSeconds().toString().padStart(2, "0");

  if (hours === "00") {
    return `${minutes}:${seconds}`;
  }

  return `${hours}:${minutes}:${seconds}`;
}

export const TranscriptItem = forwardRef<HTMLDivElement, TranscriptItemProps>((props: TranscriptItemProps, ref) => {
  return (
    <div ref={ref} className={c("rounded-xl border py-2 px-4", props.active ? "bg-yellow-50" : "bg-gray-50")}>
      <div className="flex justify-between">
        <div style={{ color: props.participant.color }}>{props.participant.fullName}</div>
        <div className="text-gray-600 text-sm">
          <button onClick={() => props.onTimeClick(props.startTimeSeconds)}>
            {formatSeconds(props.startTimeSeconds)}
          </button>
        </div>
      </div>
      <div>{props.transcript}</div>
    </div>
  );
});
