export type AudioRecordingActionsProps = {
  search: string;
  setSearch: (search: string) => void;
};

export function AudioRecordingActions(props: AudioRecordingActionsProps) {
  return (
    <button className="flex items-center text-xs gap-2">
      <div className="text-sm">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="size-3"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
          />
        </svg>
      </div>
      <input
        type="text"
        placeholder="Search conversation..."
        className="placeholder:text-gray-700 outline-none"
        value={props.search}
        onChange={(e) => props.setSearch(e.target.value)}
      />
    </button>
  );
}
