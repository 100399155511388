import { Fragment, Participant } from "./transcript-item";

export type ParticipantItemProps = {
  participant: Participant;
  duration: number;
  fragments: Fragment[];
};

export function ParticipantItem(props: ParticipantItemProps) {
  return (
    <div className="flex items-center">
      <div className="w-[220px] flex-shrink-0 flex gap-2 items-center" style={{ color: props.participant.color }}>
        <span>{props.participant.fullName}</span>
        <button className="text-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="size-4"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
            />
          </svg>
        </button>
      </div>
      <div className="relative h-3 w-full">
        <div className="absolute h-3 bg-gray-200 w-full rounded-full top-0 left-0"></div>
        {props.fragments.map((fragment) => {
          const fragmentDuration = fragment.endTimeSeconds - fragment.startTimeSeconds;

          if (fragmentDuration <= 0) {
            return null;
          }

          const fragmentDurationPercentage = fragmentDuration / props.duration;
          const fragmentOffsetPercentage = fragment.startTimeSeconds / props.duration;

          return (
            <div
              className="absolute h-3 w-8 rounded-full top-0 left-0"
              style={{
                backgroundColor: props.participant.color,
                width: `${fragmentDurationPercentage * 100}%`,
                left: `${fragmentOffsetPercentage * 100}%`,
              }}
            />
          );
        })}
      </div>
    </div>
  );
}
