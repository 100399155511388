import { useEffect, useMemo, useRef, useState } from "react";
import { useWavesurfer } from "@wavesurfer/react";
import { TranscriptItem } from "./components/transcript-item";
import { ParticipantItem } from "./components/participant-item";
import { data } from "./data";
import { Panel, PanelTitle } from "./components/panel";
import { AudioRecordingActions } from "./components/audio-recording";

function App() {
  const audioRef = useRef<HTMLDivElement>(null);
  const waveRef = useRef<HTMLDivElement>(null);
  const transcriptRefs = useRef<Map<number, HTMLDivElement>>(new Map());

  const [activeFragmentId, setActiveFragmentId] = useState<number | null>(null);
  const [search, setSearch] = useState<string>("");
  const [hover, setHover] = useState<React.CSSProperties>({
    left: 0,
    height: 0,
  });

  const { wavesurfer, isPlaying, currentTime } = useWavesurfer({
    container: waveRef,
    url: "/sample-2.mp3",
    waveColor: "#e5e7eb",
    progressColor: "#6b7280",
    height: 100,
    barWidth: 4,
    barRadius: 6,
    cursorWidth: 0,
    autoplay: false,
    dragToSeek: true,
  });

  const currenTimeSeconds = Math.floor(currentTime);

  function validateEvent(event: MouseEvent | PointerEvent | WheelEvent): boolean {
    if (waveRef.current == null) {
      return false;
    }

    if (audioRef.current == null) {
      return false;
    }

    const waveContainerRect = waveRef.current.getBoundingClientRect();

    // Out of bounds (left side)
    if (event.clientX - waveContainerRect.left < 0) {
      return false;
    }

    // Out of bounds (right side)
    if (waveContainerRect.left + waveContainerRect.width < event.clientX) {
      return false;
    }

    return true;
  }

  function onClick(event: MouseEvent) {
    const isValid = validateEvent(event);

    if (!isValid || waveRef.current == null || wavesurfer == null) {
      return;
    }

    const waveRect = waveRef.current.getBoundingClientRect();
    const offset = event.clientX - waveRect.left;
    const relativeOffset = Math.min(1, Math.max(0, offset / waveRect.width));

    wavesurfer.seekTo(relativeOffset);
  }

  function onPointerMove(event: PointerEvent | WheelEvent) {
    const isValid = validateEvent(event);

    if (!isValid || audioRef.current == null) {
      setHover({ display: "none" });
      return;
    }

    const audioContainerRect = audioRef.current.getBoundingClientRect();

    setHover({
      left: event.clientX - audioContainerRect.left,
      height: audioContainerRect.height,
    });
  }

  function onPointerLeave() {
    setHover({ display: "none" });
  }

  function onTimeClick(seconds: number) {
    wavesurfer?.setTime(seconds);
  }

  useEffect(() => {
    const container = audioRef.current;

    if (container == null) {
      return;
    }

    container.addEventListener("click", onClick);
    container.addEventListener("pointermove", onPointerMove);
    container.addEventListener("pointerleave", onPointerLeave);
    container.addEventListener("wheel", onPointerMove);

    return () => {
      container.removeEventListener("click", onClick);
      container.removeEventListener("pointermove", onPointerMove);
      container.removeEventListener("pointerleave", onPointerLeave);
      container.removeEventListener("wheel", onPointerLeave);
    };
  }, [audioRef, wavesurfer]);

  useEffect(() => {
    setActiveFragmentId(
      data.fragments.find((f) => currenTimeSeconds < f.endTimeSeconds && currenTimeSeconds >= f.startTimeSeconds)
        ?.fragmentId ?? null,
    );
  }, [currenTimeSeconds]);

  useEffect(() => {
    if (activeFragmentId == null) {
      return;
    }

    transcriptRefs.current.get(activeFragmentId)?.scrollIntoView({ behavior: "smooth" });
  }, [activeFragmentId]);

  // Very naive approach for filtering...
  const filteredFragments = useMemo(() => {
    if (search.trim().length === 0) {
      return data.fragments;
    }

    return data.fragments.filter((f) => f.transcript.toLocaleLowerCase().includes(search.toLocaleLowerCase()));
  }, [search]);

  return (
    <div className="flex w-full h-full flex-col">
      <header className="h-16 border-b flex items-center p-8 justify-between">
        <div>
          <h1 className="italic font-light">Annotair.com</h1>
        </div>
        <div className="flex items-center justify-center gap-2">
          <div className="relative flex items-center justify-center rounded-full bg-gray-200 text-gray-500 h-7 w-7 text-xs leading-none">
            JD
          </div>
          <div className="text-sm">John Doe</div>
        </div>
      </header>
      <div className="flex flex-col flex-grow gap-4 m-4">
        <div>
          <Panel>
            <PanelTitle
              title="Summary"
              actions={[
                <button className="flex items-center text-xs gap-2">
                  <div className="text-sm">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="size-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
                      />
                    </svg>
                  </div>
                  <span>Download</span>
                </button>,
              ]}
            />
            <div className="flex p-4 justify-between">
              <div className="flex gap-10">
                <div className="flex flex-col">
                  <span className="text-gray-600 text-xs uppercase mb-1">Date</span>
                  <span className="">{new Intl.DateTimeFormat().format(data.callStartedAt)}</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-600 text-xs uppercase mb-1">Duration</span>
                  <span className="">{Math.ceil(data.duration / 60)} min</span>
                </div>
                <div className="flex flex-col">
                  <span className="text-gray-600 text-xs uppercase mb-1">Participants</span>
                  <span className="">{Object.keys(data.participants).length}</span>
                </div>
              </div>
            </div>
          </Panel>
        </div>
        <div className="flex flex-grow gap-4">
          <Panel className="w-2/3">
            <PanelTitle
              title="Audio Recording"
              actions={[<AudioRecordingActions search={search} setSearch={setSearch} />]}
            />

            <div ref={audioRef} className="relative mt-6 mx-6">
              <div className="absolute top-0 w-[2px] h-40 bg-gray-400 z-50" style={hover}></div>
              <div className="flex">
                <div className="w-[220px] flex-shrink-0 flex items-center justify-center">
                  <button onClick={() => wavesurfer?.playPause()} className="text-gray-900 text-xl rounded-full">
                    {isPlaying && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={0.5}
                        stroke="currentColor"
                        className="size-10"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 5.25v13.5m-7.5-13.5v13.5" />
                      </svg>
                    )}
                    {!isPlaying && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={0.5}
                        stroke="currentColor"
                        className="size-10"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5.25 5.653c0-.856.917-1.398 1.667-.986l11.54 6.347a1.125 1.125 0 0 1 0 1.972l-11.54 6.347a1.125 1.125 0 0 1-1.667-.986V5.653Z"
                        />
                      </svg>
                    )}
                  </button>
                </div>
                <div className="flex-grow">
                  <div ref={waveRef} />
                </div>
              </div>
              <div className="pt-6">
                <ul className="flex flex-col gap-1">
                  <li>
                    <ParticipantItem
                      duration={data.duration}
                      participant={data.participants[1]}
                      fragments={data.fragments.filter((f) => f.participantId === 1)}
                    />
                  </li>
                  <li>
                    <ParticipantItem
                      duration={data.duration}
                      participant={data.participants[2]}
                      fragments={data.fragments.filter((f) => f.participantId === 2)}
                    />
                  </li>
                </ul>
              </div>
            </div>
            <div className="pt-6 px-4">
              <ul className="flex flex-col gap-4 h-[30rem] overflow-auto">
                {filteredFragments.map((fragment) => (
                  <li key={fragment.fragmentId}>
                    <TranscriptItem
                      active={activeFragmentId === fragment.fragmentId}
                      ref={(node) => {
                        node != null
                          ? transcriptRefs.current.set(fragment.fragmentId, node)
                          : transcriptRefs.current.delete(fragment.fragmentId);
                      }}
                      startTimeSeconds={fragment.startTimeSeconds}
                      onTimeClick={onTimeClick}
                      participant={{
                        id: fragment.participantId,
                        fullName: data.participants[fragment.participantId].fullName,
                        color: data.participants[fragment.participantId].color,
                      }}
                      transcript={fragment.transcript}
                    />
                  </li>
                ))}
              </ul>
            </div>
          </Panel>
          <Panel className="w-1/3">
            <PanelTitle title="Video Recording" />
          </Panel>
        </div>
      </div>
    </div>
  );
}

export default App;
