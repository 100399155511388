export const data = {
  callStartedAt: new Date(),
  duration: 60,
  participants: {
    1: {
      id: 1,
      fullName: "Florian",
      color: "#7e22ce",
    },
    2: {
      id: 2,
      fullName: "Arno",
      color: "#b91c1c",
    },
  },
  fragments: [
    {
      fragmentId: 1,
      participantId: 1,
      startTimeSeconds: 0,
      endTimeSeconds: 2,
      transcript: "Transcript...",
    },
    {
      fragmentId: 2,
      participantId: 2,
      startTimeSeconds: 2,
      endTimeSeconds: 4,
      transcript: "Transcript...",
    },
    {
      fragmentId: 3,
      participantId: 1,
      startTimeSeconds: 4,
      endTimeSeconds: 8,
      transcript: "Transcript... Search for Apple",
    },
    {
      fragmentId: 4,
      participantId: 2,
      startTimeSeconds: 8,
      endTimeSeconds: 12,
      transcript: "Transcript...",
    },
    {
      fragmentId: 5,
      participantId: 2,
      startTimeSeconds: 14,
      endTimeSeconds: 20,
      transcript: "Transcript...",
    },
    {
      fragmentId: 6,
      participantId: 1,
      startTimeSeconds: 20,
      endTimeSeconds: 26,
      transcript: "Transcript...",
    },
    {
      fragmentId: 7,
      participantId: 2,
      startTimeSeconds: 26,
      endTimeSeconds: 40,
      transcript: "Transcript...",
    },
    {
      fragmentId: 8,
      participantId: 2,
      startTimeSeconds: 42,
      endTimeSeconds: 58,
      transcript: "Transcript...",
    },
  ],
} as const;
